<template>
  <v-flex xs12 sm5>
    <v-card id="login_view" :class="smUp ? 'fields_container':'fields_container mobile_color'">
      <v-layout column :align-start="smUp" :align-center="!smUp">
        <v-layout row justify-center>
          <v-img contain   max-height="150"
  max-width="250" class="image_logo" :src="logo_img" />
        </v-layout>
        <!-- Start email textfield -->
        <v-layout row justify-center>
          <p class="field_texts" v-text="email_text" />
        </v-layout>
        <v-layout row justify-center class="password_layout">
          <v-text-field
            v-on:keyup.enter="set_login"
            v-model="user.sEmail"
            color="guest_primary"
            :placeholder="email_text"
          />
        </v-layout>
        <!-- End email textfield -->

        <!-- Start passowrd textfield -->
        <v-layout row justify-center>
          <p class="field_texts" v-text="password_text" />
        </v-layout>
        <v-layout row justify-center class="mail_layout">
          <v-text-field
            v-on:keyup.enter="set_login"
            v-model="user.sPassword"
            :append-icon="loginPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (loginPasswordVisible = !loginPasswordVisible)"
            :type="loginPasswordVisible ? 'text' : 'password'"
            color="guest_primary"
            :placeholder="password_text"
          />
        </v-layout>
        <!-- End passowrd textfield -->
        <v-layout style="width: 100%;" row justify-center align-center>
          <v-btn
            class="login_btn"
            dense
            block
            color="secondary"
            @click="set_login"
            :loading="load_btn"
          >
            <p class="btn_login_text" v-html="btn_text" />
          </v-btn>
        </v-layout>
      </v-layout>
    </v-card>
  </v-flex>
</template>
<script>
export default {
  data: () => ({
    load_btn: false,
    btn_text: "Iniciar Sesión",
    loginPasswordVisible: false,
    email_text: "Correo electrónico",
    password_text: "Contraseña",
    user: {
      sPassword: "",
      sEmail: "",
    },
  }),
  props: {
    smUp: Boolean,
    logo_img: String,
  },
  methods: {
    set_login() {
      if (this.user.sPassword && this.user.sEmail) {
        this.login();
      } else {
        this.$store.commit("toggle_alert", {
          color: "fail",
          text: "Favor de llenar todos los campos.",
        });
      }
    },
    reset_user() {
      this.user = {
        sPassword: "",
        sEmail: "",
      };
    },
    // login function manages states
    login: function () {
      this.load_btn = true;
      this.$store
        .dispatch("login", this.user)
        .then((resp) => {
          this.load_btn = false;
          this.reset_user();
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
        })
        .catch((err) => {
          console.log(err);
          this.load_btn = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
  },
  computed: {
    user_type() {
      return this.$store.state.user_type;
    },
  },
};
</script>
<style 
  scoped>
.mobile_color {
  background-color: rgba(255, 255, 255, 0.94);
}
.mail_layout {
  width: 100%;
}
.login_btn {
  margin-top: 60px;
}
.btn_login_text {
  letter-spacing: 0.5px;
  text-transform: none;
  margin: 20px;
  color: white;
  font-size: 12px;
  font-family: "Open sans", sans-serif;
  margin: auto;
}
.password_layout {
  width: 100%;
  margin-bottom: 10px;
}
.field_texts {
  margin-bottom: -10px;
  font-family: "Open sans", sans-serif;
  color: "black";
  font-size: 12px;
  letter-spacing: 0.1px;
}
.image_logo {
  margin-bottom: 50px;

}
.fields_container {
  padding: 20%;
  height: 100vh;
  width: 100%;
}
</style>